import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { LoggedInUserService } from '../../features/auth/services/logged-in-user.service';
import { ClaimMenuItemEnum } from '../../features/user-management/enums/claim-module.enum';
import { Claim } from '../../features/user-management/models/claim';
import { BaseComponent } from '../../shared/components/base/base.component';
import { NavItem } from '../model/nav-item.model';
import { TourStepIndex } from '../model/tour-steps.model';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent extends BaseComponent implements OnInit {
  loggedInUserClaims: ClaimMenuItemEnum[] = [];

  navMenuItems: NavItem[] = [
    {
      name: 'navigation.home',
      link: '/home',
      icon: 'home',
      claimMenuItems: [ClaimMenuItemEnum.HOME],
    },
    {
      name: 'navigation.userManagement',
      icon: 'people',
      claimMenuItems: [ClaimMenuItemEnum.USERS, ClaimMenuItemEnum.GROUPS, ClaimMenuItemEnum.ROLES],
      tourStepIndex: TourStepIndex.MANAGE_ALL_USERS,
      children: [
        {
          name: 'navigation.users',
          link: '/user-management/users',
          claimMenuItems: [ClaimMenuItemEnum.USERS],
        },
        {
          name: 'navigation.groups',
          link: '/user-management/groups',
          claimMenuItems: [ClaimMenuItemEnum.GROUPS],
        },
        {
          name: 'navigation.roles',
          link: '/user-management/roles',
          claimMenuItems: [ClaimMenuItemEnum.ROLES],
        },
      ],
    },
    {
      name: 'navigation.applications',
      icon: 'apps',
      tourStepIndex: TourStepIndex.MANAGE_ALL_APPLICATIONS,
      claimMenuItems: [
        ClaimMenuItemEnum.ADMIN_APPLICATIONS,
        ClaimMenuItemEnum.ADMIN_APPLICATIONS_ACCESS_REQUESTS,
        ClaimMenuItemEnum.STAFF_APPLICATIONS,
        ClaimMenuItemEnum.STAFF_APPLICATIONS_ACCESS_REQUESTS,
      ],

      children: [
        {
          name: 'navigation.apps',
          link: '/applications/apps',
          icon: 'app_registration',
          claimMenuItems: [
            ClaimMenuItemEnum.ADMIN_APPLICATIONS,
            ClaimMenuItemEnum.STAFF_APPLICATIONS,
          ],
        },
        {
          name: 'navigation.accessRequests',
          link: '/applications/access-requests',
          icon: 'vpn_key',
          claimMenuItems: [
            ClaimMenuItemEnum.ADMIN_APPLICATIONS_ACCESS_REQUESTS,
            ClaimMenuItemEnum.STAFF_APPLICATIONS_ACCESS_REQUESTS,
          ],
        },
      ],
    },
    {
      name: 'navigation.organization',
      link: '/organization-management',
      icon: 'key',
      claimMenuItems: [ClaimMenuItemEnum.ORGANIZATIONS],
    },
    {
      name: 'navigation.report',
      icon: 'area_chart',
      tourStepIndex: TourStepIndex.GENERATE_ALL_REPORTS,
      claimMenuItems: [ClaimMenuItemEnum.ADMIN_REPORTS, ClaimMenuItemEnum.ORGANIZATIONS],
      children: [
        {
          name: 'navigation.reportsAll',
          link: '/reports-management/reports',
          claimMenuItems: [ClaimMenuItemEnum.ADMIN_REPORTS, ClaimMenuItemEnum.ORGANIZATIONS],
        },
        {
          name: 'navigation.securityReports',
          link: '/reports-management/security-reports',
          claimMenuItems: [ClaimMenuItemEnum.ADMIN_REPORTS],
        },
      ],
    },
    {
      name: 'navigation.profile',
      link: '/profile-settings',
      icon: 'person',
      claimMenuItems: [ClaimMenuItemEnum.PROFILE],
      tourStepIndex: TourStepIndex.ACCOUNT_PROFILE,
    },
    {
      name: 'navigation.settings',
      icon: 'settings',
      tourStepIndex: TourStepIndex.SETUP_STAFF_ACCOUNT,
      claimMenuItems: [
        ClaimMenuItemEnum.SETTINGS_CUSTOM_BRANDING,
        ClaimMenuItemEnum.SETTINGS_NOTIFICATIONS,
        ClaimMenuItemEnum.SETTINGS_USER_MANAGEMENT,
      ],

      children: [
        {
          name: 'navigation.customBranding',
          link: '/settings/custom-branding',
          claimMenuItems: [ClaimMenuItemEnum.SETTINGS_CUSTOM_BRANDING],
        },
        {
          name: 'navigation.userManagementSettings',
          link: '/settings/user-management',
          claimMenuItems: [ClaimMenuItemEnum.SETTINGS_USER_MANAGEMENT],
        },
        {
          name: 'navigation.notificationSettings',
          link: '/settings/notifications',
          claimMenuItems: [ClaimMenuItemEnum.SETTINGS_NOTIFICATIONS],
        },
        {
          name: 'navigation.activeDirectory',
          link: '/settings/active-directory',
          claimMenuItems: [ClaimMenuItemEnum.USERS],
        },
        {
          name: 'navigation.workflowManagement',
          link: '/settings/workflow-management',
          claimMenuItems: [ClaimMenuItemEnum.WORKFLOW],
        },
      ],
    },
    {
      name: 'navigation.support',
      link: '/support',
      icon: 'help',
      claimMenuItems: [ClaimMenuItemEnum.SUPER_ADMIN_SUPPORT, ClaimMenuItemEnum.ADMIN_SUPPORT],
      tourStepIndex: TourStepIndex.SUPPORT,
    },
  ];

  constructor(private loggedInUserService: LoggedInUserService) {
    super();
  }

  ngOnInit(): void {
    this.getLoggedInUserClaims();
  }

  getLoggedInUserClaims() {
    this.loggedInUserService.$loggedInUser.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.loggedInUserClaims = this.getUniqueMenuItemClaims(user?.role.claims);
    });
  }

  getUniqueMenuItemClaims(claims?: Claim[]): ClaimMenuItemEnum[] {
    const menuItems = claims
      ?.map((claim) => claim.menuItem)
      .filter((menuItem) => menuItem !== null);
    return [...new Set(menuItems)];
  }

  deselectAllItems() {
    this.navMenuItems.forEach((item) => this.deselectItem(item));
  }

  private deselectItem(item: any) {
    if (item.children) {
      item.children.forEach((child: any) => this.deselectItem(child));
    }
  }
}
